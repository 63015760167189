import {
  AppBar,
  DialogContent,
  IconButton,
  Step,
  StepButton,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material";
import ResponsiveFullScreenModal from "../../../../components/layout/ResponsiveFullScreenModal";
import { useBoundStore } from "../../../../utils/stores/BoundStore";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptInfo from "./ReceiptInfo";
import ReceiptSplit from "./ReceiptSplit";
import ReceiptSupportFile from "./ReceiptSupportFile";
import { useCallback, useEffect, useState } from "react";
import ReceiptFormControl from "./ReceiptFormControl";
import { CREATE_RECEIPT, READ_DOCUMENT_AI, UPDATE_RECEIPT } from "./gql";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { SupportFileType } from "../../../../types/SupportFileType";
import { validateForm } from "./FormValidation";
import ReceiptFormError from "./ReceiptFormError";
import { ReceiptFormErrorType } from "../../../../types/ReceiptFormErrorType";
import { ReceiptType } from "../../../../types/ReceiptType";
import { GET_BILLING } from "../BillingForm/gql";
import { ReadDocumentAiType } from "../../../../types/ReadDocumentAiType";
import moment from "moment";
import { checkIsReceiptEditable } from "../../../../utils/receipt";
import CameraPro from "../../../../components/Camera/Camera";

const SPLIT_PER_RECEIPT_FORMSTEPS = ["Účtenka", "Rozpočty", "Podklady"];
const SPLIT_PER_BILLLING_FORMSTEPS = ["Účtenka"];

//pruvodce pro pridani uctenky
const ReceiptForm = () => {
  const { billingId } = useParams();
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const selectedToken = useBoundStore((state) => state.selectedToken);
  const updateBilling = useBoundStore((state) => state.updateBilling);

  const updateReceiptInStore = useBoundStore((state) => state.updateReceipt);

  const isReceiptFormOpen = useBoundStore((state) => state.isReceiptFormOpen);
  const receiptFormAction = useBoundStore((state) => state.receiptFormAction);
  const setIsReceiptFormOpen = useBoundStore(
    (state) => state.setIsReceiptFormOpen
  );
  const resetReceipt = useBoundStore((state) => state.resetReceipt);
  const receipt = useBoundStore((state) => state.receipt);

  const fileInput = useBoundStore((state) => state.fileInput);
  const addFileInput = useBoundStore((state) => state.addFileInput);
  const setFileInput = useBoundStore((state) => state.setFileInput);

  const billingSplitType = useBoundStore((state) => state.billingSplitType);
  const isBillingDisabled = useBoundStore((state) => state.isBillingDisabled);

  const setIsReceiptEditable = useBoundStore(
    (state) => state.setIsReceiptEditable
  );

  const isReceiptEditable = useBoundStore((state) => state.isReceiptEditable);

  const isUseAi = useBoundStore((state) => state.isUseAi);

  const [supportFileInput, setSupportFileInput] = useState<SupportFileType[]>(
    []
  );

  const [supportFileInputDelete, setSupportFileInputDelete] = useState<
    number[]
  >([]);

  const [activeStep, setActiveStep] = useState(0);

  const [formErrors, setFormErrors] = useState<ReceiptFormErrorType>({});

  const [formSteps, setFormSteps] = useState(SPLIT_PER_RECEIPT_FORMSTEPS);

  const [
    createReceipt,
    { data: createReceiptData, loading: createReceiptLoading },
  ] = useMutation(CREATE_RECEIPT, {
    update(cache, { data: { createReceipt } }) {
      if (billingId) {
        let data: any = cache.readQuery({
          query: GET_BILLING,
          variables: {
            accessToken: selectedToken,
            billingId: parseInt(billingId),
          },
        });
        // console.log("current billing data in cachce");
        // console.log(data);
        // console.log("new receipt");
        // console.log(createReceipt);
        //FIXME: until marek fixes update billing which returns array in receipts

        let currentReceipts = JSON.parse(JSON.stringify(data.billing.receipts));
        let newReceipts = [createReceipt];

        if (currentReceipts) {
          newReceipts = [createReceipt].concat(currentReceipts);
        }

        updateBilling("receipts", newReceipts);

        if (createReceipt.updatedAt) {
          updateBilling("updatedAt", createReceipt.updatedAt);
        }

        let newBillingData = JSON.parse(JSON.stringify(data.billing));

        newBillingData["receipts"] = newReceipts;

        console.log("NEW BILLING DATA ");
        console.log(newBillingData);

        cache.writeQuery({
          query: GET_BILLING,
          variables: {
            accessToken: selectedToken,
            billingId: parseInt(billingId),
          },
          data: {
            billing: newBillingData,
          },
        });
      }
    },
  });

  const [
    updateReceipt,
    { data: updateReceiptData, loading: updateReceiptLoading },
  ] = useMutation(UPDATE_RECEIPT, {
    update(cache, { data: { updateReceipt } }) {
      if (billingId) {
        let data: any = cache.readQuery({
          query: GET_BILLING,
          variables: {
            accessToken: selectedToken,
            billingId: parseInt(billingId),
          },
        });

        let newReceipts: ReceiptType[] = JSON.parse(
          JSON.stringify(data.billing.receipts)
        );

        if (data.billing.receipts) {
          const indexReceiptToUpdate = data.billing.receipts.findIndex(
            (receiptItem: ReceiptType) =>
              receiptItem.receiptId === updateReceipt.receiptId
          );

          if (indexReceiptToUpdate > -1) {
            newReceipts[indexReceiptToUpdate] = updateReceipt;
          }
        }

        updateBilling("receipts", newReceipts);

        if (updateReceipt.updatedAt) {
          updateBilling("updatedAt", updateReceipt.updatedAt);
        }

        let newBillingData = JSON.parse(JSON.stringify(data.billing));

        newBillingData["receipts"] = newReceipts;

        console.log("BILLING NEW CACHED DATA");
        console.log(newBillingData);

        cache.writeQuery({
          query: GET_BILLING,
          variables: {
            accessToken: selectedToken,
            billingId: parseInt(billingId),
          },
          data: {
            billing: newBillingData,
          },
        });
      }
    },
  });

  const [readDocumentAI, { loading: readDocumentAILoading }] = useMutation(
    READ_DOCUMENT_AI,
    {}
  );

  useEffect(() => {
    if (
      receiptFormAction === "newTakePhoto" ||
      receiptFormAction === "editTakePhoto"
    ) {
      setActiveStep(-1);
    } else if (
      receiptFormAction === "newUploadFile" ||
      receiptFormAction === "editUploadFile"
    ) {
      setActiveStep(0);
    } else {
      setActiveStep(0);
    }
  }, [receiptFormAction, isReceiptFormOpen]);

  useEffect(() => {
    if (billingSplitType === "splitReceipt") {
      setFormSteps(SPLIT_PER_RECEIPT_FORMSTEPS);
    } else {
      setFormSteps(SPLIT_PER_BILLLING_FORMSTEPS);
    }
  }, [billingSplitType, isReceiptFormOpen]);

  useEffect(() => {
    if (receipt && checkIsReceiptEditable(receipt)) {
      setIsReceiptEditable(true);
    } else {
      setIsReceiptEditable(false);
    }
  }, [receipt]);

  const handleReceiptFormClose = () => {
    setIsReceiptFormOpen(false);
    resetReceiptForm();
    setFileInput([]);

    setSupportFileInput([]);
  };

  const resetReceiptForm = () => {
    resetReceipt();
  };

  const handleNext = () => {
    const formValidation = validateForm(receipt, activeStep);

    if (!isBillingDisabled && isReceiptEditable) {
      const isValid = formValidation.isValid;
      const errors = formValidation.errors;
      setFormErrors(errors);

      if (!isValid) return;
    }

    const nextStep = activeStep + 1;
    if (formSteps.length - 1 >= nextStep) {
      setActiveStep(nextStep);
    }
  };
  const handlePrev = () => {
    const prevStep = activeStep - 1;
    if (prevStep >= 0) {
      setActiveStep(prevStep);
    }
  };

  const handleTakePhoto = (base64Photo: string) => {
    addFileInput({ file: base64Photo });
    handleNext();
  };

  const handleReadDocumentAi = useCallback(
    (file: string) => {
      readDocumentAI({
        variables: {
          fileInput: {
            upload: fileInput
              .filter((filterItem) => typeof filterItem.file !== "string")
              .map((fileInputItem) => fileInputItem.file),

            base64Upload: fileInput
              .filter((filterItem) => typeof filterItem.file === "string")
              .map((fileInputItem) => fileInputItem.file),
          },
        },
        onError(error) {
          console.log(error.message);
          // openPopupMessage(error?.message, "error");
          openPopupMessage("Automatické rozpoznaní se nezdařilo, vyplňtě údaje ručně", "success");
        },
        onCompleted(data) {
          const readDocumentAiResponse: ReadDocumentAiType =
            data.readDocumentAI;

          if (
            "warning" in readDocumentAiResponse &&
            readDocumentAiResponse.warning !== null
          ) {
            openPopupMessage(readDocumentAiResponse.warning, "warning");
          } else {
            console.log("READ DOCUMENT completed");
            console.log(readDocumentAiResponse);

            if (readDocumentAiResponse.issueDate) {
              if (moment(readDocumentAiResponse.issueDate).isValid()) {
                updateReceiptInStore(
                  "issueDate",
                  moment(readDocumentAiResponse.issueDate).format("YYYY-MM-DD")
                );
              }
            }

            if (readDocumentAiResponse.sum) {
              updateReceiptInStore("sum", readDocumentAiResponse.sum);
            }

            openPopupMessage("Úspěšně doplněno", "success");
          }
        },
      });
    },
    [fileInput, openPopupMessage, readDocumentAI, updateReceiptInStore]
  );

  useEffect(() => {
    if (
      isUseAi &&
      !(
        receiptFormAction === "editTakePhoto" ||
        receiptFormAction === "editUploadFile"
      )
    ) {
      if (fileInput && fileInput.length > 0) {
        console.log("CALLING SCAPI");
        handleReadDocumentAi("");
      }
    }
  }, [fileInput, handleReadDocumentAi, isUseAi, receiptFormAction]);

  const handleSave = () => {
    const formValidation = validateForm(receipt, activeStep);

    const isValid = formValidation.isValid;
    const errors = formValidation.errors;
    setFormErrors(errors);

    if (!isValid) return;

    if (
      receiptFormAction === "edit" ||
      receiptFormAction === "editTakePhoto" ||
      receiptFormAction === "editUploadFile"
    ) {
      handleUpdate();
    } else {
      console.log("CREATE NEW RECEIPT");
      console.log(receipt);
      handleCreate();
    }
  };

  const handleUpdate = () => {
    let receiptToUpdate = JSON.parse(JSON.stringify(receipt));

    delete receiptToUpdate["recommendedCostType"];
    delete receiptToUpdate["supplier"];
    delete receiptToUpdate["vat"];
    delete receiptToUpdate["state"];
    delete receiptToUpdate["__typename"];

    if (receiptToUpdate.splits && receiptToUpdate.splits.length > 0) {
      for (let i = 0; i < receiptToUpdate.splits.length; i++) {
        delete receiptToUpdate.splits[i]["__typename"];
      }
    }

    receiptToUpdate.sum = parseFloat(receiptToUpdate.sum);

    console.log("UPDATE RECEIPT");
    console.log(receiptToUpdate);

    updateReceipt({
      variables: {
        accessToken: selectedToken,
        // fileInput: null,
        fileInput: {
          upload: fileInput
            .filter((filterItem) => typeof filterItem.file !== "string")
            .map((fileInputItem) => fileInputItem.file),

          base64Upload: fileInput
            .filter((filterItem) => typeof filterItem.file === "string")
            .map((fileInputItem) => fileInputItem.file),
        },
        receiptInput: receiptToUpdate,
        supportFileInput: {
          upload: supportFileInput
            .filter((filterItem) => typeof filterItem.file !== "string")
            .map((fileInputItem) => fileInputItem.file),

          base64Upload: supportFileInput
            .filter((filterItem) => typeof filterItem.file === "string")
            .map((fileInputItem) => fileInputItem.file),
          delete: supportFileInputDelete,
        },
      },
      onError(error) {
        console.log(error.message);
        openPopupMessage(error?.message, "error");
      },
      onCompleted: (data) => {
        console.log("Updated Receipt");
        console.log(data.updateReceipt);
        handleReceiptFormClose();
        openPopupMessage("Uloženo", "success");
      },
    });
  };

  const handleCreate = () => {
    let newReceipt = JSON.parse(JSON.stringify(receipt));

    createReceipt({
      variables: {
        billingId: billingId,
        accessToken: selectedToken,
        fileInput: {
          upload: fileInput
            .filter((filterItem) => typeof filterItem.file !== "string")
            .map((fileInputItem) => fileInputItem.file),

          base64Upload: fileInput
            .filter((filterItem) => typeof filterItem.file === "string")
            .map((fileInputItem) => fileInputItem.file),
        },
        receiptInput: newReceipt,
        supportFileInput: {
          upload: supportFileInput
            .filter((filterItem) => typeof filterItem.file !== "string")
            .map((fileInputItem) => fileInputItem.file),

          base64Upload: supportFileInput
            .filter((filterItem) => typeof filterItem.file === "string")
            .map((fileInputItem) => fileInputItem.file),
        },
      },
      onError(error) {
        console.log(error.message);
        openPopupMessage(error?.message, "error");
      },
      onCompleted: (data) => {
        console.log("Added Receipt");
        console.log(data.createReceipt);
        handleReceiptFormClose();
        openPopupMessage("Uloženo", "success");
      },
    });
  };

  const handleActiveStepChange = (newIndex: number) => {
    if (activeStep === 0 && newIndex !== 0) {
      handleNext();
      return;
    } else {
      setActiveStep(newIndex);
    }
  };

  return (
    <ResponsiveFullScreenModal isOpen={isReceiptFormOpen}>
      {activeStep === -1 && (
        <ResponsiveFullScreenModal isOpen={true}>
          <CameraPro
            takePhoto={handleTakePhoto}
            close={handleReceiptFormClose}
          />
        </ResponsiveFullScreenModal>
      )}
      <AppBar
        sx={{ position: "relative", background: "white", color: "black" }}
      >
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h1" color="black">
            {receiptFormAction === "edit"
              ? "Úprava účtenky"
              : "Přidání účtenky"}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleReceiptFormClose}
            aria-label="close"
            data-testid="close-billing-config-modal"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Stepper
          nonLinear
          activeStep={activeStep}
          sx={{ marginBottom: "24px" }}
        >
          {formSteps.map((label, index) => (
            <Step key={label}>
              <StepButton
                color="inherit"
                onClick={() => handleActiveStepChange(index)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <ReceiptFormError errors={formErrors} />

        {activeStep === 0 && (
          <ReceiptInfo
            next={handleNext}
            save={handleSave}
            isSaveLoading={createReceiptLoading || updateReceiptLoading}
            isReadDocumentAiLoading={readDocumentAILoading}
            errors={formErrors}
          />
        )}
        {activeStep === 1 && <ReceiptSplit />}
        {activeStep === 2 && (
          <ReceiptSupportFile
            uploadedFiles={receipt.receiptSupportFiles || []}
            setSupportFile={setSupportFileInput}
            setSupportFileDelete={setSupportFileInputDelete}
            supportFiles={supportFileInput}
            supportFilesDelete={supportFileInputDelete}
          />
        )}
      </DialogContent>

      {activeStep > 0 && (
        <ReceiptFormControl
          next={handleNext}
          prev={handlePrev}
          activeStep={activeStep}
          save={handleSave}
          isLoading={createReceiptLoading || updateReceiptLoading}
        />
      )}
    </ResponsiveFullScreenModal>
  );
};

export default ReceiptForm;
