import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { NavLink } from "react-router-dom";
import EnableCamera from "../../../images/help/enable_camera.gif";
import EnableCameraIphone from "../../../images/help/enable_camera_iphone.gif";

const HelpFaq = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              component={NavLink}
              to={"/help"}
              sx={{ fontSize: "1.15em" }}
            >
              <HomeOutlinedIcon
                color="primary"
                fontSize="inherit"
                sx={{
                  position: "relative",
                  top: "3px",
                }}
              />
            </Link>
            <Typography color="text.primary">Často kladené otázky</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={"2.4em"}>Často kladené otázky</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={9}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <Typography fontSize={"1.5em"} id="specifika">
                Specifika používání aplikace
              </Typography>

              <Typography fontSize={"1.2em"}>
                Kdo může využívat aplikaci Carmen?
              </Typography>

              <p>
                Aplikace Carmen je zatím dostupná pouze pro zaměstnance
                pracující pro Seznam.cz, a.s. Kolegové z BORGISU, Seznam Zpráv,
                Datových center a dalších dceřiných společností musí nadále
                využívat papírové vyúčtování.
              </p>

              <Typography fontSize={"1.2em"}>
                Jak mám postupovat, pokud čerpám zálohu?
              </Typography>

              <p>
                Carmen zatím neumí pracovat se zálohami. Pokud tedy čerpáte
                zálohu, vyúčtování musíte provést tak, jak jste byli zvyklí
                dříve - tedy v papírové podobě.
              </p>

              <Typography fontSize={"1.2em"}>
                Jak mám vyúčtovat platbu firemní VISA kartou?
              </Typography>

              <p>
                Carmen v tuto chvíli zvládá pouze vyúčtování plateb hrazených z
                vašich soukromých peněz. Pokud jste použili firemní debetní VISA
                kartu, vyúčtování je potřeba provést postaru, tedy mimo
                aplikaci.
              </p>

              <Typography fontSize={"1.2em"}>
                Jak postupovat při vyúčtování v cizí měně?
              </Typography>

              <p>
                V nastavení vyúčtování se zvolí požadovaná měna. Je potřeba dbát na to, aby vyúčtování obsahovalo vždy účtenky jen k jednomu dni z důvodu měnícího se kurz.
              </p>

              <Typography fontSize={"1.5em"} id="vyplnovani">Vyplnění údajů</Typography>

              <Typography fontSize={"1.2em"}>
                Kam mám vyplnit informace o zákazníkovi?
              </Typography>

              <p>
                Informace o zákazníkovi se vyplňují do poznámky k účtenkám.
                Obsáhlejší informace mohou být připojené k účtence nebo
                vyúčtování formou podkladů.
              </p>

              <Typography fontSize={"1.2em"}>
                Kam mám vyplnit částku za spropitné?
              </Typography>

              <p>
                Hodnota spropitného se započítává do celé sumy účtenky. Do
                podkladů je potřeba nahrát stvrzenku z platebního terminálu.
                Hotovostní spropitné není možné proplatit.
              </p>

              <Typography fontSize={"1.2em"}>
                Jaký mám použít rozpočet?
              </Typography>

              <p>Konkrétní případy je nejlepší konzultovat s účetní.</p>

              <Typography fontSize={"1.2em"}>
                Je možné mít více rozpracovaných vyúčtování?
              </Typography>

              <p>
                Ano, může být vhodné rozpracovat si vyúčtování a postupně do něj
                přidávat účtenky například v průběhu teambuildingu nebo parkování
                za celý měsíc. Teprve po přidání všech účtenek pak odešlete
                vyúčtování.
              </p>

              <Typography fontSize={"1.2em"}>
                Mohu po nafocení a vyplnění údajů účtenku vyhodit?
              </Typography>

              <p>
                Raději ne. Dokud nebude vyúčtování proplacené tak se může stát,
                že bude účetní potřebovat nafotit účtenku znovu třeba z důvodu
                nečitelných údajů.
              </p>

              <Typography fontSize={"1.2em"}>
                Můžu změnit možnost zadání rozpočtů?
              </Typography>

              <p>
                Ano, kdykoliv je možné změnit skrze nastavení vyúčtování možnost
                zadávání rozpočtů. Rozpočty se tím nemažou a v případě potřeby
                můžete změnu vrátit.
              </p>

              <Typography fontSize={"1.5em"} id="vyuctovani">Vyúčtování</Typography>

              <Typography fontSize={"1.2em"}>
                Proč se mi vrátilo vyúčtování?
              </Typography>

              <p>
                Důvod vrácení nalezneš přímo v detailu vyúčtování v horní části.
                Pro další informace můžeš kontaktovat účetní.
              </p>

              <Typography fontSize={"1.2em"}>
                Proč mám stále vyúčtování ve zpracování?
              </Typography>

              <p>
                Může se stát, že někdy může trvat zpracování vyúčtování déle.
                Pro další informace můžeš kontaktovat účetní.
              </p>

              <Typography fontSize={"1.2em"}>Můžu zrušit odeslání?</Typography>

              <p>
                Po odeslání vyúčtování vznikne požadavek na schválení v Neznámu. V Centru požadavků - Moje žádosti můžete žádost zrušit a vyúčtování se vrátí do původního stavu před odesláním.
              </p>

              <Typography fontSize={"1.5em"} id="aplikace">Práce s aplikací</Typography>

              <Typography fontSize={"1.2em"}>
                Proč mi nefunguje foťák?
              </Typography>
              
              <Box textAlign="center" mt={4} mb={4}>
                <img width="60%" src={EnableCamera} alt="povolení přístupu ke kameře" />
              </Box>

              <Box textAlign="center" mt={4} mb={4}>
                <img width="60%" src={EnableCameraIphone} alt="povolení přístupu ke kameře iphone" />
              </Box>

              <p>
                Pro focení z telefonu je potřeba povolit v prohlížeči přístup ke
                kameře.
              </p>

              <Typography fontSize={"1.2em"}>
                Proč se nemohu přihlásit?
              </Typography>

              <p>
                Pokud máte funkční přihlášení do interních služeb, ale nemůžete
                se dostat do Carmen, tak prosím kontaktujte Hellboys.
              </p>
            </Paper>
          </Grid>
          <Grid item md={3}>
            <Paper sx={{ padding: theme.spacing(3) }}>
              <List>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#specifika">
                    <ListItemText primary="Specifika" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#vyplnovani">
                    <ListItemText primary="Vyplňování" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#vyuctovani">
                    <ListItemText primary="Vyúčtování" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton component="a" href="#aplikace">
                    <ListItemText primary="Aplikace" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HelpFaq;
