import { Box } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useBoundStore } from "../../../utils/stores/BoundStore";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { BillingType } from "../../../types/BillingType";
import { STATES } from "../../../utils/billing";
import Loading from "../../../components/Loading";
import { GET_BILLING } from "./BillingForm/gql";
import BillingForm from "./BillingForm";
import ErrorPage from "../../../components/ErrorPage";

const Billing = () => {
  const { billingId } = useParams();
  const openPopupMessage = useBoundStore((state) => state.openPopupMessage);
  const selectedToken = useBoundStore((state) => state.selectedToken);
  const setAppLoading = useBoundStore((state) => state.setAppLoading);

  const billing = useBoundStore((state) => state.billing);
  // const billingSplitType = useBoundStore((state) => state.billingSplitType);
  const setBilling = useBoundStore((state) => state.setBilling);
  const setBillingDisabled = useBoundStore((state) => state.setBillingDisabled);
  const setBillingReturned = useBoundStore((state) => state.setBillingReturned);
  const setBillingSplitType = useBoundStore(
    (state) => state.setBillingSplitType
  );

  const [
    getBilling,
    {
      data: getBillingData,
      loading: getBillingLoading,
      error: getBillingError,
    },
  ] = useLazyQuery(GET_BILLING, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (billingId && selectedToken) {
      setAppLoading(true);
      getBilling({
        variables: {
          accessToken: selectedToken,
          billingId: parseInt(billingId),
        },
        onError(error) {
          console.log(error.message);
          setAppLoading(false);
          openPopupMessage(error?.message, "error");
        },
        onCompleted(data) {
          console.log("GET BILLING DONE RESPONSE");
          console.log(data);
          setBilling(data.billing);
          setAppLoading(false);
        },
      });
    }
  }, [
    billingId,
    getBilling,
    openPopupMessage,
    selectedToken,
    setAppLoading,
    setBilling,
  ]);

  useEffect(() => {
    if (isBillingDisabled(billing)) {
      setBillingDisabled(true);
    } else {
      setBillingDisabled(false);
    }

    if (billing.state === STATES.RETURNED) {
      setBillingReturned(true);
    } else {
      setBillingReturned(false);
    }

    if (billing.isSplitBilling === 0) {
      // setFormSteps(SPLIT_PER_RECEIPT_FORMSTEPS);
      setBillingSplitType("splitReceipt");
    } else {
      // setFormSteps(SPLIT_PER_BILLLING_FORMSTEPS);
      setBillingSplitType("splitBilling");
    }
  }, [billing, setBillingDisabled, setBillingSplitType]);

  const isBillingDisabled = (document: BillingType) => {
    if (!document) return false;
    return (
      document.state === STATES.SEND ||
      document.state === STATES.APPROVED ||
      document.state === STATES.CANCELED ||
      document.state === STATES.REFUNDED ||
      document.state === STATES.TOPAY ||
      document.state === STATES.CORRECTED ||
      document.state === STATES.INPROGRESS ||
      document.state === STATES.REJECTED
    );
  };

  if (getBillingError) {
    return <ErrorPage message={getBillingError.message} />;
  }

  if (getBillingLoading || !getBillingData) {
    return (
      <Box mt={2}>
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <BillingForm billing={billing} />
    </>
  );
};

export default Billing;
